import './App.css';
import './i18n';
import CustomBar from './components/CustomBar';
import Profile from './views/Profile';
import Products from './views/Products';
import Footer from './views/Footer';
import Timeline from './views/Timeline';

import header from './images/IMG_1911.JPG'
import Contact from './views/Contact';

function App() {
  return (
    <div className="App">
      <CustomBar />
      <img src={header} className="header-logo" alt="logo" width={"100%"} />
      <Profile />
      <Timeline />
      <Products />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
