import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box, Paper, Tooltip, Typography } from '@mui/material';

export default function Timeline() {
    const [tweets, setTweets] = useState([{ tweet: "デフォルト1" }, { tweet: "デフォルト2" }]);

    useEffect(() => {
        axios.get('https://reconnection.jp:8000/publication?id=qazwsxedcrfvtgby')
            .then(response => {
                console.log('response:', response);
                setTweets(response['data']['data']);
                console.log(response['data']['data']);
            })
            .catch(error => {
                console.log('error:', error);
            })
    }, [])
    
    // 以下のBox専用のHTMLを作成予定
    return (
        <Box p={1} style={{ backgroundColor:"#53d7db" }}>
            <Typography variant="h4" align="left">
                TIMELINE
            </Typography>
            <Box p={1} style={{ backgroundColor:"#53d7db", maxHeight: '400px', overflow: 'auto' }}>
                {tweets.map((val, index) =>
                    <Box p={1}>
                        <Box style={{ display: 'flex' }}>
                            <Tooltip title={val.user}> 
                                <img alt={val.user} src={val.user_icon} height='56px' />
                            </Tooltip>
                            <Box>
                                <Paper elevation={2} style={{ display: 'table', padding: '5px', margin: '5px' }}>
                                    <Typography variant='body1' align="left">
                                        {val.tweet}
                                    </Typography>
                                </Paper>

                                <Typography variant='body2' p={1} style={{ bottom: 0, fontSize: '12px' }}>
                                    {val.time}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
                <Box style={{ fontSize: '9px' }}>
                    Supported by <a href="https://reconnection.jp/">Reconnection</a>
                </Box>
            </Box>
        </Box>
    );
}