import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { makeStyles } from '@mui/styles';
import EmailIcon from '@mui/icons-material/Email';

const useStyles = makeStyles({
    test: {
        color: 'black'
    }
});

export default function Contact() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [dialog, setDialog] = useState(false);
    const dialogOpen = () => setDialog(true);
    const dialogClose = () => setDialog(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [mail, setMail] = useState('');
    const [text, setText] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const { t, i18n } = useTranslation();
    
    const sendMail = () => {
        console.log('sendMailをクリックしました。')
        axios.post('https://reconnection.jp:8000/mail', { mail: mail, subject: 'お問い合わせフォームより', text: `Name: ${ firstname } ${ lastname }\n\n${text}` })
            .then((response) => {
                console.log('response:', response)
                handleClose()
            })
            .catch((error) => {
                console.log('error:', error)
                setDialogMessage(error.message)
                dialogOpen()
            })
    }

    const checkValidation = () => {
        const regexp = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        var validMail = false, validFirstname = false, validLastname = false, validText = false;
        var message = '';
        if(regexp.test(mail)) {
            validMail = true
        }else{
            message += 'メールアドレスが正しくありません。\n'
        }
        if(firstname.replace(/[\p{C}\p{Z}]/gu, '').length > 0) {
            validFirstname = true
        }else{
            message += 'First Nameの入力が正しくありません。\n'
        }
        if(lastname.replace(/[\p{C}\p{Z}]/gu, '').length > 0) {
            validLastname = true
        }else{
            message += 'Last Nameの入力が正しくありません。\n'
        }
        if(text.replace(/[\p{C}\p{Z}]/gu, '').length > 0) {
            validText = true
        }else{
            message += 'Messageの入力が正しくありません。\n'
        }
        setDialogMessage(message)
        
        if(validMail && validFirstname && validLastname && validText) {
            handleOpen()
        } else {
            dialogOpen()
        }
    }

    return (
        <Box style={{ backgroundColor: "#d3d3d3" }}>
            <Grid container className={classes.test} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid p={1} item xs={4} sm={4} md={6}>
                    <Typography variant="h4" align="left">
                        CONTACT
                    </Typography>
                    <Box p={2}>
                        <Typography variant="body1" align="left">
                            {t('contact.description')}
                        </Typography>
                        <Box align="right">
                            <IconButton aria-label="delete" href="https://github.com/ShinjoSato">
                                <GitHubIcon />
                            </IconButton>
                            <IconButton aria-label="delete" href="https://twitter.com/ShinjoComputing">
                                <TwitterIcon />
                            </IconButton>
                            <IconButton aria-label="delete" href="https://www.youtube.com/channel/UCiXoD-4ARHRJ15GT41Bm29g">
                                <YouTubeIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid p={1} item xs={4} sm={4} md={6}>
                    
                    <Grid container>
                        <Grid item xs={6} align="left">
                            <TextField id="standard-basic" label="First Name" value={firstname} onChange={(event) => setFirstname(event.target.value)} variant="standard" sx={{ input: { color: 'black' } }} />
                        </Grid>
                        <Grid item xs={6} align="right">
                            <TextField id="standard-basic" label="Last Name" value={lastname} onChange={(event) => setLastname(event.target.value)} variant="standard" sx={{ input: { color: 'black' } }} />
                        </Grid>
                    </Grid>
                    <TextField id="standard-basic" label="E-Mail" variant="standard" value={mail} onChange={(event) => setMail(event.target.value)} sx={{ input: { color: 'black' } }} fullWidth />
                    <TextField
                        id="standard-multiline-static"
                        label="Message"
                        value={text}
                        onChange={(event) => setText(event.target.value)}
                        multiline
                        rows={8}
                        variant="standard"
                        fullWidth
                    />
                    <Button onClick={checkValidation} variant='contained' size="large">CHECK</Button>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '70%',
                        height: '60%',
                        backgroundColor: 'white',
                        padding: '20px'
                    }}
                >
                    <Box height={"90%"}>
                        <Box height={"15%"}>
                            <Grid container>
                                <Grid item xs={6} align="left">
                                    <TextField disabled id="standard-basic" label="First Name" value={firstname} onChange={(event) => setFirstname(event.target.value)} variant="standard" sx={{ input: { color: 'black' } }} />
                                </Grid>
                                <Grid item xs={6} align="left">
                                    <TextField disabled id="standard-basic" label="Last Name" value={lastname} onChange={(event) => setLastname(event.target.value)} variant="standard" sx={{ input: { color: 'black' } }} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box height={"15%"}>
                            <TextField disabled id="standard-basic" label="E-Mail" variant="standard" value={mail} onChange={(event) => setMail(event.target.value)} sx={{ input: { color: 'black' } }} fullWidth />
                        </Box>
                        <Box height={"15%"}>
                            <TextField
                                disabled
                                id="standard-multiline-static"
                                label="Message"
                                value={text}
                                onChange={(event) => setText(event.target.value)}
                                multiline
                                rows={8}
                                variant="standard"
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Divider />
                    <Grid2 container p={1}>
                        <Grid2 xs={6} textAlign="left">
                            <Button onClick={handleClose} variant='contained' size="large">BACK</Button>
                        </Grid2>
                        <Grid2 xs={6} textAlign="right">
                            <Button onClick={sendMail} variant='contained' size="large" startIcon={<EmailIcon />}>SUBMIT</Button>
                        </Grid2>
                    </Grid2>
                </Box>
            </Modal>
            <Dialog
                open={dialog}
                onClose={() => dialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {'Error'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dialogClose()} autoFocus>閉じる</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}