import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "language": 'English',
            profile: {
                "introduction": "I'm Shinjo Sato. I work in Japan as an Apllication Engineer and I develop apllication both at work and in my free time.",
                "description": "I have a bachelor's degree and a master's degree in Computer Science and I've experienced full-stack development a lot."
            },
            contact: {
                "description": "Feel free to contact me via social media below or the message form if you have any questions about products or myself."
            },
            products: {
                "reconnection description": "An messaging application that you can manage on-premises servers.",
                "kaibun description": "A text editor which puts 'Auto Template Editor' and 'Markup Balloon' together.",
                "markup balloon description": "A text editor which modifies prepared-text fragmentary with keeping the original text.",
                "auto template editor description": "A text editor with which you can easily create texts a lot by preparing a template-text.",
                "blog description": "A blog which contains not only techs of software but also lifestyle."
            }
        }
    },
    ja: {
        translation: {
            "language": '日本語',
            profile: {
                "introduction": "Shinjo Satoです。日本の企業でApplication Engineerをしながら個人でもアプリ開発をしています。",
                "description": "大学と大学院ではコンピュータサイエンスを専攻し、これまで多くのフルスタック開発を経験してきました。"
            },
            contact: {
                "description": "製品や私自身に関するお問い合わせは以下のSNSもしくはメッセージフォールより連絡ください。"
            },
            products: {
                "reconnection description": "オンプレミスでサーバーを管理できるメッセージングアプリ。",
                "kaibun description": "Auto Template EditorとMarkup Balloonを合わせたテキストエディター。",
                "markup balloon description": "既存テキストを部分的に修正させるテキストエディター。",
                "auto template editor description": "テンプレートとなるテキストファイルを用意するだけで手軽にテキストを量産できるテキストエディター。",
                "blog description": "技術記事などを中心としたブログ。"
            }
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "ja",
        debug: true,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;