import { Box, Grid, Typography } from '@mui/material';
import profile from '../images/profile.jpg';
import { useTranslation } from 'react-i18next';

export default function Profile() {
    const { t, i18n } = useTranslation();

    return (
        <Box>
            <Grid container columns={{ xs: 4, sm: 12, md: 12 }}>
                <Grid p={1} item xs={4} sm={5} md={5}>
                    <Typography variant="h4" align="left">
                        About ME
                    </Typography>
                </Grid>
                <Grid p={1} item xs={4} sm={2} md={2}>
                    <img src={profile} width="50%" />
                </Grid>
                <Grid p={1} item xs={4} sm={5} md={5}>
                    <Typography variant="body1" align="left" p={1}>
                        { t('profile.introduction') }
                    </Typography>
                    <Typography variant="body2" align="left" p={1}>
                        {t('profile.description')}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}