import { Box, Divider, Grid, Typography } from '@mui/material';
import { Card, CardContent, CardMedia, CardActionArea } from '@mui/material'
import { useTranslation } from 'react-i18next';

import test from '../images/IMG_1911.JPG';
import img_ate from '../images/ate.png';
import img_blog from '../images/blog.jpg';
import img_kaibun from '../images/kaibun.png';
import img_markupballoon from '../images/markupballoon.png';
// import img_markupballoon from 'https://shinkiitten.com/products/markup-balloon/images/markup-balloon-header.png';

export default function Products() {
    const { t, i18n } = useTranslation();

    return (
        <Box style={{ color: "#ffffff", backgroundColor: "#151c48" }}>
            <Typography p={1} variant="h4" align="left">
                PRODUCTS
            </Typography>
            <Grid container spacing={1} p={1} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={4} md={4}>
                    <Card>
                        <CardActionArea href="https://reconnection.jp/">
                            <CardMedia
                                component="img"
                                height="140"
                                image={test}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Reconnection
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="left">
                                    {t('products.reconnection description')}
                                </Typography>
                                <Divider />
                                <Typography variant="caption" color="text.secondary">
                                    Windows, iOS
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Card>
                        <CardActionArea href="">
                            <CardMedia
                                component="img"
                                height="140"
                                image={img_kaibun}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Kaibun
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="left">
                                    {t('products.kaibun description')}
                                </Typography>
                                <Divider />
                                <Typography variant="caption" color="text.secondary">
                                    Windows
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Card>
                        <CardActionArea href="https://shinkiitten.com/products/markup-balloon/index.html">
                            <CardMedia
                                component="img"
                                height="140"
                                image={img_markupballoon}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Markup Balloon
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="left">
                                    {t('products.markup balloon description')}
                                </Typography>
                                <Divider />
                                <Typography variant="caption" color="text.secondary">
                                    Windows
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Card>
                        <CardActionArea href="https://shinkiitten.com/products/auto-template-editor/index.html">
                            <CardMedia
                                component="img"
                                height="140"
                                image={img_ate}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Auto Template Editor
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="left">
                                {t('products.auto template editor description')}
                                </Typography>
                                <Divider />
                                <Typography variant="caption" color="text.secondary">
                                    Windows
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Card>
                        <CardActionArea href="https://sample-blog.com/">
                            <CardMedia
                                component="img"
                                height="140"
                                image={img_blog}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Blog
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="left">
                                    {t('products.blog description')}
                                </Typography>
                                <Divider />
                                <Typography variant="caption" color="text.secondary">
                                    WEB
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}