import { Box, Divider, IconButton, Typography } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function Footer() {
    return (
        <Box p={2} style={{ color: "#ffffff", backgroundColor: "#252c32" }}>
            <Box align="right">
                <IconButton aria-label="delete" style={{ color: 'white' }} href="https://github.com/ShinjoSato">
                    <GitHubIcon />
                </IconButton>
                <IconButton aria-label="delete" style={{ color: 'white' }} href="https://twitter.com/ShinjoComputing">
                    <TwitterIcon />
                </IconButton>
                <IconButton aria-label="delete" style={{ color: 'white' }} href="https://www.youtube.com/channel/UCiXoD-4ARHRJ15GT41Bm29g">
                    <YouTubeIcon />
                </IconButton>
            </Box>
            <Divider color="white" variant="middle" />
            <Typography p={1} variant="body1" align="left">
                Shinjo Sato
            </Typography>
            <Typography p={1} variant="body2" align="center">
                Copyright 2022
            </Typography>
        </Box>
    );
}